













































import { Vue, Component, Prop } from "vue-property-decorator";
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BTab,
  BTabs,
} from "bootstrap-vue";

@Component({
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
})
export default class RegistrationMaintenanceChangeStatus extends Vue {
  registrationId: string = null;
  statusId: number | null = null;

  statuses = [
    [1, "Invitation sent"],
    [2, "Link clicked"],
    [3, "First answers received"],
    [4, "Answers submitted"],
    [5, "Sent back to candidate"],
    [6, "Registration completed"],
    [7, "Registration approved"],
    [8, "Sent to CRM"],
    [9, "Cancelled by candidate"],
    [10, "Started"],
    [11, "Completed"],
  ];

  get statusObject() {
    return this.statuses.map((status) => {
      return {
        value: status[0],
        text: status[1],
      };
    });
  }

  async submit() {
    const req = await this.$api.registrations.maintenance.changeStatus(
      this.registrationId,
      this.statusId
    );
    if (req) {
      this.$toast.success("Status changed successfully");
      this.registrationId = null;
      this.statusId = null;
    } else {
      this.$toast.error("Something went wrong");
    }
  }
}
